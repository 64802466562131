import { useCallback, useEffect, useState } from 'react';
import { Address, AddressGeoParams } from '@/api/address';
import { useGetAddressGeo } from '@/queries/query/useAddressQuery';

interface UseGeoAddressProps {
  onAddressByGeoChange: (address: Address) => void;
}

const INIT_ADDRESS_GEO_PARAMS: AddressGeoParams = { lng: undefined, lat: undefined } as const;

const useGeoAddress = ({ onAddressByGeoChange }: UseGeoAddressProps) => {
  const [addressGeoParams, setAddressGeoParams] = useState(INIT_ADDRESS_GEO_PARAMS);
  const { data: addressGeoData } = useGetAddressGeo(addressGeoParams);

  const setLocationForRequestGeoLocation = useCallback((longitude: number, latitude: number) => {
    setAddressGeoParams({ lng: longitude, lat: latitude });
  }, []);

  useEffect(() => {
    if (addressGeoParams && addressGeoData) {
      const changeAddressDepth2 = {
        ...addressGeoData,
        address3: null,
        address3Code: null,
        code: `${addressGeoData.address1Code}${addressGeoData.address2Code}00000`,
        lat: addressGeoParams.lat,
        lng: addressGeoParams.lng,
      };
      onAddressByGeoChange(changeAddressDepth2);
    }
    // Do not add another dependency.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressGeoData, addressGeoParams]);

  return { addressGeoData, setLocationForRequestGeoLocation };
};

export default useGeoAddress;
