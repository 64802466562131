import styled from '@emotion/styled';
import React from 'react';
import { GLOBAL_COLOR } from '@/styles/colors';
import { toRem } from '@/utils/commonUtils';

interface ModalCenterHeaderTextProps {
  children: React.ReactNode;
  className?: string;
}

const ModalHeaderCenterText = ({ children, className }: ModalCenterHeaderTextProps) => {
  return <Wrapper className={className}>{children}</Wrapper>;
};

export default ModalHeaderCenterText;

const Wrapper = styled.h1`
  font-size: ${toRem(18)};
  font-weight: 600;
  line-height: 22px;
  color: ${GLOBAL_COLOR.GRAY_900};
  margin: auto;
`;
