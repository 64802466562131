import { useQuery } from 'react-query';
import { AddressApi, AddressGeoParams, AddressListPrams, GetAddressByHospitalTagParams } from '@/api/address';
import { QUERY_KEYS } from '@/constants/queryKey';

export const useGetAddressByCode = (code?: string) => {
  const result = useQuery(
    QUERY_KEYS.ADDRESS.BY_CODE(code),
    async () => {
      const response = AddressApi.getAddressByCode(code);
      return response;
    },
    { notifyOnChangeProps: 'tracked', enabled: !!code },
  );
  return result;
};

export const useGetAddressGeo = (params: AddressGeoParams) => {
  const result = useQuery(
    QUERY_KEYS.ADDRESS.GEO(params),
    async () => {
      const response = AddressApi.getAddressGeo(params);
      return response;
    },
    { notifyOnChangeProps: 'tracked', enabled: params.lng !== undefined && params.lat !== undefined },
  );
  return result;
};

export const useGetAddressList = (params: AddressListPrams) => {
  const result = useQuery(
    QUERY_KEYS.ADDRESS.LIST(params),
    async () => {
      const response = AddressApi.getAddressList(params);
      return response;
    },
    { notifyOnChangeProps: 'tracked' },
  );
  return result;
};

export const useGetAddressByHospitalTag = (params?: GetAddressByHospitalTagParams) => {
  const result = useQuery(
    QUERY_KEYS.ADDRESS.BY_HOSPITAL_TAG(params),
    async () => {
      const response = AddressApi.getAddressByHospitalTag(params);
      return response;
    },
    { notifyOnChangeProps: 'tracked' },
  );
  return result;
};
