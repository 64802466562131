import styled from '@emotion/styled';
import React, { useCallback } from 'react';
import { Address } from '@/api/address';
import AddressFilterContent from '@/components/address/AddressFilterContent';
import FlatIcon from '@/components/common/FlatIcon';
import ModalHeaderCenterText from '@/components/common/ModalHeaderCenterText';
import CurrentLocationButton from '@/components/common/buttons/CurrentLocationButton';
import BottomSheetModal from '@/components/modal/BottomSheetModal';
import useGeoAddress from '@/hooks/useGeoAddress';
import useGetCurrentLocation from '@/hooks/useGetCurrentLocation';
import { ModalProps } from '@/hooks/useModal';
import { GLOBAL_COLOR } from '@/styles/colors';
import { Z_INDEX } from '@/styles/zIndex';
import { toRem } from '@/utils/commonUtils';

interface AddressFilterModalProps {
  addressModalProps: ModalProps;
  selectedAddress: Address;
  onAddressChange: (address: Address) => void;
  onNationWideSearch?: () => void;
  isSearchAll?: boolean;
}

const AddressFilterModal = ({
  addressModalProps,
  selectedAddress,
  onAddressChange,
  onNationWideSearch,
  isSearchAll = false,
}: AddressFilterModalProps) => {
  const { setLocationForRequestGeoLocation } = useGeoAddress({ onAddressByGeoChange: onAddressChange });

  const handleLocationChange = useCallback(
    (longitude: number, latitude: number) => {
      setLocationForRequestGeoLocation(longitude, latitude);
    },
    [setLocationForRequestGeoLocation],
  );

  const { getCurrentLocationAsync } = useGetCurrentLocation({ onLocationChange: handleLocationChange });

  return (
    <BottomSheetModal
      modalProps={addressModalProps}
      height={90}
      isHeaderBorder={true}
      contentPadding={{ top: '0', bottom: '0', right: '0', left: '0' }}
      header={
        <AddressModalHeader>
          <p>지역 선택</p>
          <CloseButton onClick={() => addressModalProps.hideModal()}>
            <FlatIcon iconType={'icClose'} size={24} color={GLOBAL_COLOR.GRAY_300} />
          </CloseButton>
        </AddressModalHeader>
      }
      content={
        <AddressFilterContent
          isAddressOpen={addressModalProps.isShowing}
          selectedAddress={selectedAddress}
          onAddressChange={onAddressChange}
        />
      }
      bottom={
        <ButtonWrapper>
          <CurrentLocationButton handleGetLocationClick={getCurrentLocationAsync} />
          {isSearchAll && <NationwideButton onClick={onNationWideSearch}>전국 검색</NationwideButton>}
        </ButtonWrapper>
      }
    />
  );
};

export default React.memo(AddressFilterModal);

const ButtonWrapper = styled.div`
  display: flex;
  flex: 1;
  padding: ${toRem(12)} ${toRem(16)};
  box-shadow: 0 ${toRem(-5)} ${toRem(6)} rgba(0, 0, 0, 0.05);
  z-index: ${Z_INDEX.FLOAT_BUTTON};
`;

const NationwideButton = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: ${toRem(14)} 0;
  border-radius: ${toRem(8)};
  border: ${toRem(1)} solid ${GLOBAL_COLOR.GRAY_200};
  margin-left: ${toRem(8)};
  font-size: ${toRem(16)};
  font-weight: 500;
  line-height: ${toRem(20)};
  color: ${GLOBAL_COLOR.GRAY_900};
`;

const AddressModalHeader = styled(ModalHeaderCenterText)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CloseButton = styled.div`
  position: absolute;
  right: ${toRem(16)};
`;
