import { getQueryParams } from './apiUtils';
import { BASE_URL, HTTP } from './http';
import { TIME_FILTER_KEY } from '@/constants/filter';

export interface AddressGeoParams {
  lat?: number;
  lng?: number;
}

export interface Address {
  id?: number;
  code?: string;
  address1Shorts?: string;
  address1?: string;
  address1Code?: string;
  address2?: string | null;
  address2Code?: string | null;
  address3?: string | null;
  address3Code?: string | null;
  level: number;
  lat?: number | null;
  lng?: number | null;
  isBeforeDefaultAddressInitialization?: boolean;
}

export const AddressUtil = {
  getNarrowAddress: (address?: Address): string => {
    if (address?.address3) {
      return address.address3;
    }
    if (address?.address2) {
      return address.address2;
    }
    if (address?.address1) {
      return address.address1;
    }
    return '';
  },
  getAddressCodes: (address?: Address): string => {
    if (!address) {
      return '';
    }
    const codes = [address.address1Code, address.address2Code, address.address3Code];
    return codes
      .reduce((results, code) => {
        if (code) {
          results.push(code);
        }
        return results;
      }, [] as string[])
      .join('');
  },
  getAddressShortText: (address?: Address): string => {
    if (!address) {
      return '';
    }
    return `${address.address1Shorts ?? ''} ${address.address2 ?? ''}`.trim();
  },
  getAddressText: (address?: Address): string => {
    if (!address) {
      return '';
    }
    if (address.address2?.includes(ADDRESS_TYPE.ALL)) {
      return `${address.address2}`;
    }
    if (address.address3?.includes(ADDRESS_TYPE.ALL)) {
      return `${address.address1Shorts} ${address.address3}`;
    }
    return `${AddressUtil.getAddressShortText(address)} ${address.address3 ?? ''}`.trim();
  },
};

export enum ADDRESS_GEO_KEY {
  LAT = 'lat',
  LNG = 'lng',
}

export enum ADDRESS_TYPE {
  ALL = '전체',
}

export interface AddressListPrams {
  level: number;
  address1Code?: string | null;
  address2Code?: string | null;
}

export enum ADDRESS_LIST_KEY {
  LEVEL = 'level',
  ADDRESS1_CODE = 'address1Code',
  ADDRESS2_CODE = 'address2Code',
}

export interface GetAddressByHospitalTagParams {
  allDay?: boolean;
  weekEnd?: boolean;
  overNight?: boolean;
  isNew?: boolean;
  tagIds?: number;
}

export const AddressApi = {
  getAddressByCode: async (code?: string) => {
    const response = await HTTP.get<Address>(`${BASE_URL}/hospitals/addresses-code?code=${code}`);
    return response.data;
  },
  getAddressGeo: async (params: AddressGeoParams) => {
    const queryParams = getQueryParams({
      [ADDRESS_GEO_KEY.LAT]: params.lat,
      [ADDRESS_GEO_KEY.LNG]: params.lng,
    });
    const response = await HTTP.get<Address>(`${BASE_URL}/hospitals/address/reverse-geo?${queryParams}`);
    return response.data;
  },
  getAddressList: async (params: AddressListPrams) => {
    const queryParams = getQueryParams({
      [ADDRESS_LIST_KEY.LEVEL]: params.level,
      [ADDRESS_LIST_KEY.ADDRESS1_CODE]: params.address1Code,
      [ADDRESS_LIST_KEY.ADDRESS2_CODE]: params.address2Code,
    });
    const response = await HTTP.get<Address[]>(`${BASE_URL}/hospitals/addresses?${queryParams}`);
    return response.data;
  },
  getAddressByHospitalTag: async (params?: GetAddressByHospitalTagParams) => {
    const queryParams = getQueryParams({
      [TIME_FILTER_KEY.ALLDAY]: params?.allDay,
      [TIME_FILTER_KEY.WEEKEND]: params?.weekEnd,
      [TIME_FILTER_KEY.OVERNIGHT]: params?.overNight,
      isNew: params?.isNew,
      tagIds: params?.tagIds,
    });
    const response = await HTTP.get<Address[]>(`${BASE_URL}/hospitals/addresses/by-hospital-filter?${queryParams}`);
    return response.data;
  },
};
