import styled from '@emotion/styled';
import React from 'react';
import FlatIcon from '@/components/common/FlatIcon';
import { GLOBAL_COLOR } from '@/styles/colors';
import { toRem } from '@/utils/commonUtils';

interface CurrentLocationButtonProps {
  handleGetLocationClick: () => void;
  className?: string;
}

const CurrentLocationButton = ({ handleGetLocationClick, className }: CurrentLocationButtonProps) => {
  return (
    <Wrapper className={className} onClick={handleGetLocationClick}>
      <FlatIcon iconType={'icLocation'} size={20} color={GLOBAL_COLOR.GRAY_400} />
      현재 위치
    </Wrapper>
  );
};

export default CurrentLocationButton;

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${toRem(14)} 0;
  border-radius: ${toRem(8)};
  border: ${toRem(1)} solid ${GLOBAL_COLOR.GRAY_200};
  font-size: ${toRem(16)};
  font-weight: 500;
  line-height: ${toRem(20)};
  color: ${GLOBAL_COLOR.GRAY_900};

  i {
    margin-right: ${toRem(4)};
  }
`;
