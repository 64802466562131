import create from 'zustand';
import { Address, AddressGeoParams } from '@/api/address';
import { DEFAULT_ADDRESS } from '@/constants/common';

interface SelectedAddressStore {
  selectedAddress: Address;
  setSelectedAddress: (newAddress: Address) => void;
  currentLocation: AddressGeoParams;
  setCurrentLocation: (currentLocation: AddressGeoParams) => void;
}

const useSelectedAddressStore = create<SelectedAddressStore>((set) => ({
  selectedAddress: DEFAULT_ADDRESS,
  setSelectedAddress: (newAddress: Address) => {
    set((state) => ({ ...state, selectedAddress: newAddress }));
  },
  currentLocation: { lng: DEFAULT_ADDRESS.lng, lat: DEFAULT_ADDRESS.lat },
  setCurrentLocation: (currentLocation: AddressGeoParams) => {
    set((state) => ({ ...state, currentLocation }));
  },
}));

export default useSelectedAddressStore;
