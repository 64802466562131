import { useCallback } from 'react';
import { DEFAULT_LOCATION } from '@/constants/common';
import useNativeBridges from '@/hooks/useNativeBridges';
import useSelectedAddressStore from '@/store/useSelectedAddressStore';
import { isInAppFlag } from '@/utils/utilBridge';

interface UseGetCurrentLocationProps {
  onLocationPermissionRequest?: () => void;
  onLocationChange: (longitude: number, latitude: number) => void;
}

const useGetCurrentLocation = ({ onLocationPermissionRequest, onLocationChange }: UseGetCurrentLocationProps) => {
  const { getCurrentPosition } = useNativeBridges();
  const { setCurrentLocation } = useSelectedAddressStore();

  const getCurrentLocationAsync = useCallback(() => {
    if (!isInAppFlag) {
      onLocationChange(DEFAULT_LOCATION.LNG, DEFAULT_LOCATION.LAT);
      return;
    }
    getCurrentPosition()?.then((response) => {
      if (response && response.permission) {
        onLocationChange(response.coords.longitude, response.coords.latitude);
        setCurrentLocation({ lng: response.coords.longitude, lat: response.coords.latitude });
        return;
      }
      onLocationPermissionRequest?.();
    });
  }, [getCurrentPosition, onLocationChange, onLocationPermissionRequest, setCurrentLocation]);

  return { getCurrentLocationAsync };
};

export default useGetCurrentLocation;
