import styled from '@emotion/styled';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Address, ADDRESS_TYPE } from '@/api/address';
import FlatIcon from '@/components/common/FlatIcon';
import { useGetAddressList } from '@/queries/query/useAddressQuery';
import { GLOBAL_COLOR } from '@/styles/colors';
import { toRem } from '@/utils/commonUtils';

interface AddressFilterContentProps {
  isAddressOpen: boolean;
  selectedAddress: Address;
  onAddressChange: (address: Address) => void;
}

const AddressFilterContent = ({ isAddressOpen, selectedAddress, onAddressChange }: AddressFilterContentProps) => {
  const address2ContainerRef = useRef<HTMLDivElement | null>(null);
  const address2Ref = useRef<HTMLDivElement | null>(null);
  const [select1, setSelect1] = useState(selectedAddress);
  const [select2, setSelect2] = useState(select1);
  const { data: address1Data } = useGetAddressList({ level: 1 });
  const { data: address2Data } = useGetAddressList(select1);

  const memoizedAddress2 = useMemo(() => {
    if (address2Data && address2Data.length > 0) {
      const copiedAddress2Data = [...address2Data];
      const firstAddressData = copiedAddress2Data[0];
      const foundAddress1 = address1Data?.find((item) => item.address1 === firstAddressData.address1);
      const totalItem = {
        ...firstAddressData,
        id: foundAddress1?.id,
        code: `${firstAddressData.address1Code}00000000`,
        address2: `${firstAddressData?.address1Shorts} ${ADDRESS_TYPE.ALL}`,
        address2Code: foundAddress1?.address2Code,
        address3Code: foundAddress1?.address3Code,
        lat: foundAddress1?.lat,
        lng: foundAddress1?.lng,
      };
      copiedAddress2Data.unshift(totalItem);
      return copiedAddress2Data;
    }
    return [];
  }, [address1Data, address2Data]);

  const handleSelectAddress1 = useCallback(
    (address: Address) => {
      setSelect1({ ...address, level: address.level + 1 });
      if (selectedAddress.address1 === address.address1) {
        setSelect2(selectedAddress);
      } else {
        setSelect2(address);
      }
    },
    [selectedAddress],
  );

  const handleSelectAddress2 = useCallback(
    (address: Address) => {
      onAddressChange(address);
    },
    [onAddressChange],
  );

  useEffect(() => {
    setSelect1({ level: 2, address1Code: selectedAddress.address1Code });
    setSelect2({ ...selectedAddress, level: 3 });
  }, [isAddressOpen, selectedAddress]);

  useEffect(() => {
    if (address2Ref && address2Ref.current) {
      setTimeout(() => {
        const offsetTop = address2Ref.current?.offsetTop ?? 0;
        const offsetHeight = address2Ref.current?.offsetHeight ?? 0;
        const totalTop = offsetTop - offsetHeight;
        address2ContainerRef.current?.scrollTo({ top: totalTop, behavior: 'smooth' });
      }, 200);
    }
  }, [address2Ref, select2, selectedAddress]);

  return (
    <Wrapper>
      {address1Data && (
        <Address1Container>
          {address1Data.map((item1, index) => (
            <Address1
              key={`address1_${index}`}
              isSelected={select1.address1Code === item1.address1Code}
              onClick={() => handleSelectAddress1(item1)}>
              <p>{item1.address1Shorts}</p>
            </Address1>
          ))}
        </Address1Container>
      )}

      {memoizedAddress2 && (
        <Address2Container ref={address2ContainerRef}>
          {memoizedAddress2.map((item2, index) => {
            const isSelected = select2.address2Code === item2.address2Code && select2.address2 === item2.address2;

            return (
              <Address2
                key={`address2_${index}`}
                onClick={() => handleSelectAddress2(item2)}
                ref={isSelected ? address2Ref : null}
                isSelected={isSelected}>
                <p>{item2.address2}</p>
                {isSelected && <FlatIcon iconType={'icCheck'} size={24} color={GLOBAL_COLOR.BLUE_500} />}
              </Address2>
            );
          })}
        </Address2Container>
      )}
    </Wrapper>
  );
};

export default AddressFilterContent;

export const Wrapper = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
`;

const Address1Container = styled.div`
  width: ${toRem(122)};
  height: 100%;
  flex-wrap: nowrap;
  overflow-y: scroll;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const Address1 = styled.div<{ isSelected?: boolean }>`
  width: ${toRem(122)};
  display: flex;
  justify-content: center;
  padding: ${toRem(12)} 0;
  font-size: ${toRem(16)};
  border-bottom: 1px solid ${GLOBAL_COLOR.GRAY_50};
  background-color: ${({ isSelected }) => (isSelected ? GLOBAL_COLOR.WHITE : '#F9FAFB')};
  font-weight: ${({ isSelected }) => (isSelected ? 600 : 400)};
  color: ${({ isSelected }) => (isSelected ? GLOBAL_COLOR.GRAY_900 : GLOBAL_COLOR.GRAY_500)};
`;

const Address2Container = styled.div`
  flex: 1;
  height: 100%;
  flex-wrap: nowrap;
  overflow-y: scroll;
  padding: 0 ${toRem(16)};
`;

const Address2 = styled.div<{ isSelected?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${toRem(12)} 0;
  font-weight: ${({ isSelected }) => (isSelected ? 600 : 400)};
  font-size: ${toRem(16)};
  color: ${({ isSelected }) => (isSelected ? GLOBAL_COLOR.BLUE_500 : GLOBAL_COLOR.GRAY_900)};

  :not(:last-of-type) {
    border-bottom: ${toRem(1)} solid ${GLOBAL_COLOR.GRAY_50};
  }
`;
